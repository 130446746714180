/* Change background color of selected date */
.DayPickerSelectedSpan {
    background-color: #00B55933 !important; /* Light green */
    border-radius: 20px;
}

/* Change color of start and end dates */
.CalendarDay__selected_start{
    background-color: #009e60 !important; /* Dark green */
    border-top-left-radius: 50% !important;
    border-bottom-left-radius: 50% !important;
}
.CalendarDay__selected_end {
    background-color: #009e60 !important; /* Dark green */
    color: #fff !important;
    border-top-right-radius: 50% !important;
    border-bottom-right-radius: 50% !important;
}

/* Remove border and add hover effect */
.CalendarDay__selected_span, .CalendarDay__hovered_span{
    background-color: #00B55933 !important;
    color: black !important;
    border: none !important;
}

.DateRangePickerInput__block{
    border-radius: 12px !important;
}

.CalendarDay__blocked_out_of_range, .CalendarDay__blocked_out_of_range:active, .CalendarDay__blocked_out_of_range:hover,
.CalendarDay__selected, .CalendarDay__selected:active, .CalendarDay__selected:hover, .CalendarDay__default, .CalendarDay__default:hover   {
    border: none !important;
}

/* Navigation arrows */
.DayPickerNavigation_button {
    border: none !important;
}

/* Customize button colors */
.my-custom-datepicker .btn-cancel {
    border: 1px solid #009e60 !important;
    color: #009e60 !important;
}

.my-custom-datepicker .btn-ok {
    background-color: #009e60 !important;
    color: white !important;
}
