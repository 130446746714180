@import url(https://fonts.googleapis.com/css?family=Muli);
body {
  margin: 0;
  font-family: "Circular Std Book", "Roboto", "Oxygen", "Ubuntu", "Cantarell",
    "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 14px !important;
  line-height: 1 !important;
}

.MuiTableCell-root,
.MuiMenuItem-root,
.MuiButton-root,
.modal {
  font-family: "Circular Std Book", "Roboto", "Oxygen", "Ubuntu", "Cantarell" !important;
  font-size: 14px !important;
}

@font-face {
  font-family: "Circular Std Book";
  src: url(/static/media/860c3ec7bbc5da3e97233ccecafe512e.db63d454.eot); /* IE9*/
  src: url(/static/media/860c3ec7bbc5da3e97233ccecafe512e.db63d454.eot?#iefix)
      format("embedded-opentype"),
    
      url(/static/media/860c3ec7bbc5da3e97233ccecafe512e.46969fea.woff2)
      format("woff2"),
    
      url(/static/media/860c3ec7bbc5da3e97233ccecafe512e.6a94f6f2.woff)
      format("woff"),
    
      url(/static/media/860c3ec7bbc5da3e97233ccecafe512e.860c3ec7.ttf)
      format("truetype"),
    
      url("/static/media/860c3ec7bbc5da3e97233ccecafe512e.95aaa02e.svg#Circular Std Book")
      format("svg"); /* iOS 4.1- */
}

.MuiTableCell-root.MuiTableCell-footer.MuiTablePagination-root.Component-paginationRoot-1 {
  border: none !important;
}

button:focus {
  outline: none !important;
}

.modal-header {
  border-bottom: none !important;
  padding-bottom: 0 !important;
  padding-top: 20px !important;
  padding-right: 40px !important;
  padding-left: 40px !important;
}
.modal-body {
  border-bottom: none !important;
  padding: 0 !important;
}

.modal-content {
  border: none !important;
}

.MuiTypography-h4 {
  font-size: 2.125rem;
  font-family: "Circular Std Book", "Roboto", "Oxygen", "Ubuntu", "Cantarell" !important;
  font-weight: 400;
  line-height: 1.235;
  letter-spacing: 0.00735em;
}

.MuiTypography-body1 {
  font-size: 1rem;
  font-family: "Circular Std Book", "Roboto", "Oxygen", "Ubuntu", "Cantarell" !important;
  font-weight: 400;
  line-height: 1.5;
  letter-spacing: 0.00938em;
}
.MuiTypography-body1 {
  font-size: 1rem;
  font-family: "Circular Std Book", "Roboto", "Oxygen", "Ubuntu", "Cantarell" !important;
  font-weight: 400;
  line-height: 1.5;
  letter-spacing: 0.00938em;
}
.MuiTypography-body2 {
  font-size: 1rem;
  font-family: "Circular Std Book", "Roboto", "Oxygen", "Ubuntu", "Cantarell" !important;
  font-weight: 400;
  line-height: 1.5;
  letter-spacing: 0.00938em;
}
.MuiTypography-caption {
  font-size: 0.75rem;
  font-family: "Circular Std Book", "Roboto", "Oxygen", "Ubuntu", "Cantarell" !important;
  font-weight: 400;
  line-height: 1.66;
  letter-spacing: 0.03333em;
}

.MuiFormLabel-root {
  color: rgba(0, 0, 0, 0.54);
  padding: 0;
  font-size: 1rem;
  font-family: "Circular Std Book", "Roboto", "Oxygen", "Ubuntu", "Cantarell" !important;
  font-weight: 400;
  line-height: 1;
  letter-spacing: 0.00938em;
}

.MuiInputBase-input {
  font-size: 14px !important;
  font-family: "Circular Std Book", "Roboto", "Oxygen", "Ubuntu", "Cantarell" !important;
}

.__react_component_tooltip.show {
  opacity: 0.5 !important;
}

.MuiCircularProgress-colorSecondary {
  color: white !important;
}

.pengantar > p {
  margin-bottom: 0 !important;
  min-height: 14px;
  line-height: 1.6;
  color: #7f8388 !important;
}
.support > p {
  margin-bottom: 0 !important;
  min-height: 14px;
  line-height: 1.6;
  /* color: #7f8388 !important; */
}

.MuiExpansionPanel-root {
  box-shadow: none !important;
  border-top: none !important;
  border-bottom: 2px solid #ededee !important;
  padding: 5px 0px;
}
/* 
Mui-expanded {
} */

.MuiExpansionPanel-root:before {
  background-color: transparent !important;
}

.MuiExpansionPanel-root.Mui-expanded {
  margin: 0 !important;
}

.canvasjs-chart-credit {
  display: none !important;
}

.canvasjs-chart-canvas {
  font-family: "arial";
}

.dotmaps {
  box-shadow: 0px 0px 8px 10px #75ce9f;
  animation-name: dot;
  animation-duration: .5s;
}

@keyframes dot {
  0% {
    transform: scale(1);
  }
  70% {
    transform: scale(1.8);
  }
  100% {
    transform: scale(1);
  }
}

.MuiTableCell-root.MuiTableCell-footer.MuiTablePagination-root.Component-paginationRoot-1 {
  border: none !important;
}

button:focus {
  outline: none !important;
}

.modal-header {
  border-bottom: none !important;
}

.modal-content {
  border: none !important;
}

.modal-width {
  max-width: 600px !important;
}
.modal-lg {
  max-width: 800px !important;
}

.modal-width-message {
  max-width: 700px !important;
}

.modal-width-prodi {
  max-width: 750px !important;
}

.modal-width-change-status {
  max-width: 500px !important;
}

.modal-width-practice {
  max-width: 1000px !important;
}

 
/* Change background color of selected date */
.DayPickerSelectedSpan {
    background-color: #00B55933 !important; /* Light green */
    border-radius: 20px;
}

/* Change color of start and end dates */
.CalendarDay__selected_start{
    background-color: #009e60 !important; /* Dark green */
    border-top-left-radius: 50% !important;
    border-bottom-left-radius: 50% !important;
}
.CalendarDay__selected_end {
    background-color: #009e60 !important; /* Dark green */
    color: #fff !important;
    border-top-right-radius: 50% !important;
    border-bottom-right-radius: 50% !important;
}

/* Remove border and add hover effect */
.CalendarDay__selected_span, .CalendarDay__hovered_span{
    background-color: #00B55933 !important;
    color: black !important;
    border: none !important;
}

.DateRangePickerInput__block{
    border-radius: 12px !important;
}

.CalendarDay__blocked_out_of_range, .CalendarDay__blocked_out_of_range:active, .CalendarDay__blocked_out_of_range:hover,
.CalendarDay__selected, .CalendarDay__selected:active, .CalendarDay__selected:hover, .CalendarDay__default, .CalendarDay__default:hover   {
    border: none !important;
}

/* Navigation arrows */
.DayPickerNavigation_button {
    border: none !important;
}

/* Customize button colors */
.my-custom-datepicker .btn-cancel {
    border: 1px solid #009e60 !important;
    color: #009e60 !important;
}

.my-custom-datepicker .btn-ok {
    background-color: #009e60 !important;
    color: white !important;
}

.marker {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 18px;
    height: 18px;
    background-color: #000;
    border: 2px solid #fff;
    border-radius: 100%;
    -webkit-user-select: none;
        -ms-user-select: none;
            user-select: none;
    transform: translate(-50%, -50%);
    &:hover {
      z-index: 1;
    }
  }
